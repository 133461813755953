.result {
    max-width: 100%;
    margin: 2rem auto;
    table-layout: fixed;
    border-spacing: 1rem;
    text-align: right;
  }
  
  .result thead {
    font-size: 0.7rem;
    color: #261dbf;
  }
  
  .result tbody {
    font-family: "Poppins", sans-serif;
    font-size: 0.85rem;
    color: #ffffff;
  }

  @media (max-width: 500px) {
    .result {
      border-spacing: 4px;
      margin: 1rem auto;
    }

    .result thead {
      font-size: 0.6rem;
      text-align: center
    }

    .result tbody {
      font-size: 0.7rem;
    }
  }