.header {
    text-align: center;
    margin: 3rem auto;
  }
  
  
  .header img {
    width: 11rem;
    height: 11rem;
    object-fit: contain;
    background-color: transparent;
  }
  
  .header h1 {
    font-size: 1.5rem;
    letter-spacing: 1px;
    font-variant: small-caps;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2);
  }

  @media (max-width: 800px) {
    .header h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }