footer {
    width: 100%;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
  }
  
  footer img {
    width: 170px;
    height: auto;
  }
  
  @media (max-width: 480px) {
  
    footer {
      font-size: 1rem;
    }
  
    footer img {
      width: 200px;
    }
  
  }