@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;family=Poppins:wght@100;200;300;400;500;600;700;800;900&amp;family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&amp;display=swap');


* {
  box-sizing: border-box;
}

body {
  height: auto;
  width: 100vw;
  margin: 0;
  font-family: "Poppins", sans-serif;
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(38,38,55,1) 50%, rgba(2,0,36,1) 100%);
  color: #e1eeeb;
  letter-spacing: 0.04rem;
  overflow-x: hidden;
}
